export const DEFAULT_APP_COLOR = '#1d3998';
export const DEFAULT_BUTTON_COLOR = '#1d3998';
export const DEFAULT_BUTTON_TEXT_COLOR = '#ffffff';
export const DEFAULT_LINK_COLOR = '#1d3998';
export const DEFAULT_ACCOUNT_LOCALE = 'cs';

export const MAX_DESCRIPTION_LENGTH = 1500;

export const MENU_LEVEL_THRESHOLD = 3;

export const LITE_SIDEBAR_SLUG = 'lite-sidebar';

export const JWT_TOKEN_ISSUER = 'munipolis.com';
export const JWT_GUEST_USER_ID = 0;
export const JWT_USER_SOURCE_GUEST = 'guest';
export const JWT_MUNIPOLIS_APP_MY_MR = 'MyMr';
export const JWT_GUEST_FIRSTNAME = 'guest';
export const JWT_GUEST_LASTNAME = 'guest';
export const JWT_AUDIENCE_MR_API = 'MrAPI';
export const JWT_AUDIENCE_ADMIN_API = 'AdminAPI';

export const SIDEBAR_FIXED_TOP_POSITION = 112;
export const SIDEBAR_FIXED_WIDTH_REDUCTION = 36;
export const SIDEBAR_FIXED_HEIGHT_REDUCTION = 130;
export const SIDEBAR_FIXED_LEFT_REDUCTION = 16;
