import { isCached } from '~/utils/helpers';
import { useLiteSidebarStore } from '~/stores/liteSidebarStore';
import { getCustomPageData } from '~/utils/dataProviders/CustomPageApiDataProvider';
import { useAccountStore } from '~/stores/accountStore';
import { LITE_SIDEBAR_SLUG } from '~/utils/constants';

export async function loadLiteSidebar(): Promise<void> {
    const store = useLiteSidebarStore();
    const { id } = useAccountStore().state;

    if (isCached(store.state.loadedAt)) {
        return;
    }

    try {
        const sidebar = await getCustomPageData(id, LITE_SIDEBAR_SLUG);

        store.setLiteSidebarState({
            title: sidebar.title,
            content: sidebar.description,
            loadedAt: Date.now(),
        });
    } catch (e) {
        return;
    }
}

