export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    AccountDetail: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query AccountDetail($domain: String!) {
    accountDetail(
        filter: [{ field: "my_mr_lite_domain", operator: eq, value: $domain }]
    ) {
        id
        name
        slug
        appCityId
        logo
        background
        web
        locale
        registrationLink
        colorScheme {
            appColor
            buttonColor
            buttonTextColor
            linkColor
            welcomeColor
            welcomeImagePath
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountDetail', 'query'),
    AccountCustomMenuItemsTree: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query AccountCustomMenuItemsTree($accountId: Int!) {
    accountCustomMenuItemsTree(account_id: $accountId) {
        id
        title
        type
        orderBy
        position
        link
        pageSlug
        groupIds
        postGroupIds
        hiddenHeader
        hasArchive
        customMenuIcon {
            title
            iconSvg
        }
        items {
            id
            title
            type
            orderBy
            position
            link
            pageSlug
            groupIds
            postGroupIds
            hiddenHeader
            hasArchive
            customMenuIcon {
                title
                iconSvg
            }
            items {
                id
                title
                type
                orderBy
                position
                link
                pageSlug
                groupIds
                postGroupIds
                hiddenHeader
                hasArchive
                customMenuIcon {
                    title
                    iconSvg
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountCustomMenuItemsTree', 'query'),
    PublicCustomPage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query PublicCustomPage($accountId: String!, $slug: String!) {
    publicCustomPage(
        filter: [
            { field: "account.id", operator: eq, value: $accountId },
            { field: "slug", operator: eq, value: $slug }
        ]
    ) {
        id
        title
        description
        slug
        customPageItems {
            id
            title
            description
            url
            image
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicCustomPage', 'query')
  }
}