import { defineStore } from 'pinia';
import type { ILiteSidebarState } from '~/types/store.types';
import { computed, ref } from 'vue';

export const useLiteSidebarStore = defineStore('liteSidebarStore', () => {

    // State
    const state = ref<ILiteSidebarState>({
        title: '',
        content: '',
        loadedAt: 0,
    });

    // Getters
    const hasLiteSidebar = computed((): boolean => {
        return state.value.content !== '';
    });

    // Actions
    const setLiteSidebarState = (liteSidebarState: ILiteSidebarState) => {
        state.value = liteSidebarState;
    };

    return {
        state,
        hasLiteSidebar,
        setLiteSidebarState,
    };
});
