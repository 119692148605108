import { getMenuItems } from '~/utils/dataProviders/AccountApiDataProvider';
import { useAccountStore } from '~/stores/accountStore';
import { useNavigationStore } from '~/stores/navigationStore';
import type { INavItem } from '~/types/types';
import { EMenuType } from '~/types/enums';
import { type RouteLocationNormalized } from 'vue-router';
import { findTreeLineByPath, isCached } from '~/utils/helpers';
import type { MenuItem } from '~/types/adminApi';
import { loadLiteSidebar } from '~/utils/LiteSidebarLoader';

export async function loadNavItemsWithSidebar(to: RouteLocationNormalized) {
    const navigationStore = useNavigationStore();
    const areNavItemsCached = isCached(navigationStore.state.loadedAt);

    if (areNavItemsCached) {
        setNavigationOnLoad(to, navigationStore);
        return;
    }

    const { id: accountId } = useAccountStore().state;
    const menuItems: MenuItem[] = await getMenuItems(accountId);

    navigationStore.setNavItems(
        mapNavItems(menuItems.filter(isMenuItemSupported))
    );

    setNavigationOnLoad(to, navigationStore);
    navigationStore.setLoadedAt(Date.now());
    await loadLiteSidebar();
}

function isMenuItemSupported(menuItem: MenuItem): boolean {
    return isSupportedMenuType(menuItem.type) && hasNotGroupRestriction(menuItem.groupIds);
}

function isSupportedMenuType(itemType: string): boolean {
    return ['custom', 'custom_page', 'rss', 'posts_page', 'menu_collection', 'dashboard'].includes(itemType);
}

function hasNotGroupRestriction(itemGroupIds: number[] | null): boolean {
    return (!itemGroupIds || itemGroupIds.length === 0);
}

function makeAppLink(menuItem: MenuItem): string {
    if (menuItem.type === 'custom') {
        return menuItem.link;
    }

    if (menuItem.type === 'custom_page') {
        return `/p/${menuItem.link}`;
    }

    if (menuItem.type === 'rss') {
        return `/r/${menuItem.pageSlug}`;
    }

    if (menuItem.type === 'posts_page') {
        return `/n/${menuItem.pageSlug}`;
    }

    if (menuItem.type === 'menu_collection') {
        return `/m/${menuItem.pageSlug}`;
    }

    if (menuItem.type === 'dashboard') {
        return '/dashboard';
    }

    return '#';
}

function makeSlug(menuItem: MenuItem): string {
    if (menuItem.type === 'custom') {
        return '#' + menuItem.id;
    }

    if (menuItem.type === 'custom_page') {
        return menuItem.link;
    }

    if (menuItem.type === 'rss') {
        return menuItem.pageSlug;
    }

    if (menuItem.type === 'posts_page') {
        return menuItem.pageSlug;
    }

    if (menuItem.type === 'menu_collection') {
        return menuItem.pageSlug;
    }

    if (menuItem.type === 'dashboard') {
        return 'dashboard';
    }

    return '#';
}

function mapNavItems(menuItems: MenuItem[], child= false): INavItem[] {
    return menuItems.map((item: MenuItem, index: Number) => ({
        id: item.id,
        label: item.title,
        link: (index === 0 && !child) ? '/' : makeAppLink(item),
        icon: item.customMenuIcon.iconSvg,
        type: item.type,
        orderBy: item.orderBy,
        slug: makeSlug(item),
        items: item.items && item.items.length > 0 ? mapNavItems(item.items.filter(isMenuItemSupported), true) : [],
        rss: item.type === EMenuType.RSS ? item.link : null,
        postGroupIds: item.postGroupIds,
        hiddenHeader: item.hiddenHeader,
        hasArchive: item.hasArchive,
    }));
}

function setNavigationOnLoad(to: RouteLocationNormalized, store: any) {
    const activeItemTreeLine = findTreeLineByPath(store.state.navItems, to.path.replace('/archive', ''));
    if (activeItemTreeLine && activeItemTreeLine.length > 0) {
        store.state.activeNavItems = [];
        activeItemTreeLine.forEach((item: INavItem) => {
            store.state.activeNavItems.push(item);
        });

        const currentNavigationLevel = activeItemTreeLine.length;
        const currentNavItem = activeItemTreeLine[currentNavigationLevel - 1];
        store.setPageDetails(
            currentNavItem.label,
            currentNavItem.description,
            currentNavItem.hiddenHeader,
            currentNavItem.icon,
        );
    }
}
