<template>
    <div
        class="page-loader flex items-center justify-center w-full h-[100vh]"
        data-test="page-loader"
    >
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0024 0C4.47726 0 0 4.47726 0 10.0024C0 14.3653 2.79114 18.071 6.68731 19.4427C7.0874 19.5856 7.51131 19.2808 7.50655 18.8521C7.50655 18.8473 7.50655 18.8378 7.50655 18.8331C7.50655 18.5759 7.33984 18.3472 7.09693 18.2615C3.69136 17.0612 1.25268 13.8176 1.25268 10.0071C1.25268 5.12979 5.23934 1.186 10.1357 1.25744C14.7988 1.32412 18.6378 5.1155 18.7521 9.77852C18.8521 13.689 16.3849 17.0422 12.9126 18.2615C12.6697 18.3472 12.503 18.5759 12.503 18.8331V18.8521C12.4982 19.2808 12.9221 19.5856 13.3222 19.4427C17.2136 18.0757 20.0048 14.3653 20.0048 10.0071C20.0048 4.47726 15.5275 0 10.0024 0Z"
                fill="#231F20"
                class="outer-circle"
            />
            <path
                d="M9.94967 4.68725C12.7884 4.65867 15.1795 6.91159 15.3129 9.7456C15.4081 11.7604 14.3793 13.5417 12.8027 14.5182C12.617 14.6325 12.5074 14.8325 12.5074 15.0469C12.5074 15.5375 13.0456 15.8375 13.4648 15.5803C15.3319 14.4229 16.5703 12.3557 16.5703 9.99804C16.5703 6.37336 13.6315 3.43457 10.0068 3.43457C6.38215 3.43457 3.44336 6.37336 3.44336 9.99804C3.44336 12.3557 4.68651 14.4229 6.54886 15.5803C6.96801 15.8375 7.50623 15.5375 7.50623 15.0469C7.50623 14.8325 7.39668 14.6325 7.21092 14.5182C5.70104 13.5798 4.69128 11.908 4.69128 9.99804C4.69128 7.08306 7.03945 4.71583 9.94967 4.68725Z"
                fill="#231F20"
                class="inner-circle"
            />
            <path
                d="M12.5031 10.0025C12.5031 8.62127 11.3838 7.50195 10.0025 7.50195C8.62127 7.50195 7.50195 8.62127 7.50195 10.0025C7.50195 11.1695 8.29738 12.1459 9.37859 12.4222V19.3762C9.37859 19.7191 9.65961 20.0002 10.0025 20.0002C10.3455 20.0002 10.6265 19.7191 10.6265 19.3762V12.4222C11.7077 12.1459 12.5031 11.1647 12.5031 10.0025ZM10.0025 10.9361C9.48338 10.9361 9.06423 10.517 9.06423 9.99778C9.06423 9.47861 9.48338 9.05947 10.0025 9.05947C10.5217 9.05947 10.9409 9.47861 10.9409 9.99778C10.9409 10.517 10.5217 10.9361 10.0025 10.9361Z"
                fill="#231F20"
                class="antenna"
            />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
    @keyframes fade-in-and-out {
        0%, 100% { opacity: 0; }
        35% , 65% { opacity: 0.6; }
    }

    .page-loader svg {
        width: 52px;
        height: 50px;
    }

    .antenna,
    .inner-circle,
    .outer-circle {
        opacity: 0;
        animation-name: fade-in-and-out;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
    }

    .inner-circle {
        animation-delay: 0.1s;
    }

    .outer-circle {
        animation-delay: 0.2s;
    }
</style>
