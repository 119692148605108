import { getAccountByDomain } from '~/utils/dataProviders/AccountApiDataProvider';
import { isCached, resolveCurrentDomainHost } from '~/utils/helpers';
import { useAccountStore } from '~/stores/accountStore';
import { DEFAULT_APP_COLOR, DEFAULT_BUTTON_COLOR, DEFAULT_BUTTON_TEXT_COLOR, DEFAULT_LINK_COLOR } from '~/utils/constants';

export async function loadAccount() {
    const store = useAccountStore();

    if (isCached(store.state.loadedAt)) {
        return;
    }

    store.setIsLoading(true);

    const account = await getAccountByDomain(resolveCurrentDomainHost());

    store.setAccountState({
        id: account.id,
        name: account.name,
        slug: account.slug,
        appCityId: account.appCityId,
        logo: account.logo,
        background: account.background,
        web: account.web,
        locale: account.locale,
        appColor: !account.colorScheme?.appColor ? DEFAULT_APP_COLOR : account.colorScheme.appColor,
        buttonColor: !account.colorScheme?.buttonColor ? DEFAULT_BUTTON_COLOR : account.colorScheme.buttonColor,
        buttonTextColor: !account.colorScheme?.buttonTextColor ? DEFAULT_BUTTON_TEXT_COLOR : account.colorScheme.buttonTextColor,
        linkColor: !account.colorScheme?.linkColor ? DEFAULT_LINK_COLOR : account.colorScheme.linkColor,
        registrationLink: account.registrationLink,
        loadedAt: Date.now(),
    });

    store.setIsLoading(false);
}
