import routerOptions0 from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/my-mr-lite/my-mr-lite/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}