import { type Ref } from 'vue';
import moment from 'moment';
import type { INavItem } from '~/types/types';

export const resolveCurrentDomainHost = (): string => {
    const url: URL = useRequestURL();
    const host: string = url.hostname;

    if (host === 'localhost') {
        return useRuntimeConfig().public.app.domainHost;
    }

    return host;
};

/*
    Within this method, all nav items are separated into groups of n items (based on screen width)
    and the group that includes the current active primary item is returned.
*/
export const getNavItemsGroupToDisplay = (screenWidth: number, allMenuItems: INavItem[], navStore: any) => {
    let displayCount;
    const groups = [];

    if (screenWidth >= 992) {
        displayCount = 7;
    } else if (screenWidth >= 768) {
        displayCount = 5;
    } else if (screenWidth >= 540) {
        displayCount = 4;
    } else {
        displayCount = 3;
    }

    for (let i = 0; i < allMenuItems.length; i += displayCount) {
        groups.push(allMenuItems.slice(i, i + displayCount));
    }

    const groupWithActiveNavItem = groups.find(group => group.includes(navStore.getActiveNavItemForGivenLevel(1)));

    return groupWithActiveNavItem || groups[0];
};

/*
    The method goes through all items in the tree and returns an array containing all items
    of a tree line, that includes the item with item.link equal to path (second argument)
*/
export const findTreeLineByPath = (items: INavItem[], link: string) => {
    let nodes: INavItem[] = [];

    function traverse(item: INavItem, currentNodes: INavItem[]) {
        currentNodes.push(item);

        if (item.link === link) {
            nodes = currentNodes.slice();
            return; // found the link, no need to continue searching
        }

        for (const child of item.items) {
            traverse(child, currentNodes.slice());
        }
    }

    for (const item of items) {
        traverse(item, []);
    }

    return nodes;
};

export const closeModalIfClickedOutside = (
    event: Event,
    showModalRef: Ref,
    modalSelector: string,
    buttonSelector: string
): void => {
    const menuModal: HTMLElement | null = document.querySelector(modalSelector);
    const modalOpenButton: HTMLElement | null = document.querySelector(buttonSelector);
    if (!menuModal?.contains(event.target as Node) && !modalOpenButton?.contains(event.target as Node)) {
        showModalRef.value = false;
    }
};

export const isCached = (loadedAt: number): boolean => {
    const now = new Date();
    return loadedAt > now.setMinutes(now.getMinutes() - 5);
};

export const formatDate = (dateString: string, format?: string): string => {
    return moment(dateString).format(format || 'DD.MM.YYYY');
};

export const formatArchiveDate = (dateString: string, format?: string): string => {
    return moment(dateString).format(format || 'DD.MM.YYYY HH:mm');
};

export const generateUuid = () => {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};

export const truncateLabel = (label: string, maxDisplayLength: number): string => {
    return label.length > maxDisplayLength ? label.substring(0, maxDisplayLength) + '...' : label;
};

export const truncateLabelWithSuffix = (label: string, maxDisplayLength: number): string => {
    const labelParts = label.split('.');
    const suffix = labelParts[labelParts.length -1];
    return label.length > maxDisplayLength ? label.substring(0, maxDisplayLength) + `...${suffix}` : label;
};

export const stripTags = (html: string) => {
    const newHtml = (' ' + html).slice(1);
    return newHtml.replace(/(<([^>]+)>)/ig,'');
};
