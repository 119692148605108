import { defineStore } from 'pinia';
import {
    DEFAULT_ACCOUNT_LOCALE,
    DEFAULT_APP_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_LINK_COLOR
} from '~/utils/constants';
import { ref } from 'vue';
import type { IAccountState } from '~/types/store.types';

export const useAccountStore = defineStore('accountStore', () => {

    // State
    const state = ref<IAccountState>({
        id: 0,
        name: '',
        slug: '',
        appCityId: 0,
        locale: DEFAULT_ACCOUNT_LOCALE,
        logo: '',
        background: '',
        web: '',
        appColor: DEFAULT_APP_COLOR,
        buttonColor: DEFAULT_BUTTON_COLOR,
        buttonTextColor: DEFAULT_BUTTON_TEXT_COLOR,
        linkColor: DEFAULT_LINK_COLOR,
        registrationLink: '',
        isLoading: false,
        loadedAt: 0,
    });

    // Actions
    function setAccountState(accountState: IAccountState) {
        state.value = accountState;
    }

    function setIsLoading(isLoading: boolean) {
        state.value.isLoading = isLoading;
    }

    return {
        state,
        setAccountState,
        setIsLoading,
    };
});
