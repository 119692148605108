<template>
    <div>
        <Transition mode="out-in">
            <div>
                <page-loader v-if="isLoading" />
                <NuxtLayout v-else>
                    <NuxtPage />
                </NuxtLayout>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import PageLoader from '~/components/common/PageLoader.vue';
    import { useAccountStore } from '~/stores/accountStore';
    import defaultFavicon from '~/assets/images/favicon/favicon.png';

    const { linkColor, logo } = useAccountStore().state;
    const isLoading = ref(true);
    const favicon = logo || defaultFavicon;

    useHead({
        link: [
            { rel: 'icon', type: 'image/x-icon', href: favicon }
        ]
    });

    onMounted(() => {
        isLoading.value = false;
    });
</script>

<style lang="scss">
    .v-enter-active,
    .v-leave-active {
        transition: all 0.5s ease;
    }

    .v-enter-from {
        transform: translateY(15px);
        opacity: 0;
    }

    .v-leave-to {
        transform: translateY(-15px);
        opacity: 0;
    }

    // set styles for all components - this style tag is not scoped
    .lite-sidebar,
    .page-description,
    .timeline-item,
    .page-item {
        a:not(.btn) {
            color: v-bind(linkColor);
            font-weight: 600;
            transition: all 0.15s ease;

            &:not(.pb-image-link):hover {
                filter: brightness(120%);
                text-decoration: underline;
            }
        }
    }

    .page-icon svg {
        width: 26px;
        max-height: 30px;
    }
</style>
