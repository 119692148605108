import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    routes: (_routes) => [
        {
            name: 'Home',
            path: '/',
            component: () => import('~/pages/Homepage.vue').then(r => r.default || r)
        },
        {
            name: 'Dashboard',
            path: '/dashboard',
            component: () => import('~/pages/DashboardPage.vue').then(r => r.default || r)
        },
        {
            name: 'Menu Collection page',
            path: '/m/:slug',
            component: () => import('~/pages/MenuCollectionPage.vue').then(r => r.default || r)
        },
        {
            name: 'Posts page archive',
            path: '/n/:slug/archive',
            props: { archive: true },
            component: () => import('~/pages/PostsPage.vue').then(r => r.default || r)
        },
        {
            name: 'Posts page',
            path: '/n/:slug',
            component: () => import('~/pages/PostsPage.vue').then(r => r.default || r)
        },
        {
            name: 'Custom page',
            path: '/p/:slug',
            component: () => import('~/pages/CustomPage.vue').then(r => r.default || r)
        },
        {
            name: 'RSS page',
            path: '/r/:slug',
            component: () => import('~/pages/RssPage.vue').then(r => r.default || r)
        },
        {
            name: 'Sitemap',
            path: '/sitemap',
            component: () => import('~/pages/SitemapPage.vue').then(r => r.default || r)
        },
        {
            name: 'Single Post page',
            path: '/t/:id',
            component: () => import('~/pages/SinglePostPage.vue').then(r => r.default || r)
        },
    ],

    scrollBehavior(to: { hash: string }, from, savedPosition) {
        const nuxtApp = useNuxtApp();

        return new Promise((resolve) => {
            nuxtApp.hooks.hook('page:finish', async () => {
                if (to.hash) {
                    // wait 800 ms before scrolling to hash (waiting for page content to load)
                    setTimeout(() => {
                        resolve({
                            el: to.hash,
                            top: 0,
                            behavior: 'smooth'
                        });
                    }, 800);
                }

                if (savedPosition) {
                    // scroll to last saved position if there is no hash in the url
                    resolve(savedPosition);
                }
            });
        });
    }
};
