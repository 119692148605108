import validate from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45check_45apex_45domain_45global from "/home/runner/work/my-mr-lite/my-mr-lite/middleware/01.checkApexDomain.global.ts";
import _02_45build_45auth_45tokens_45global from "/home/runner/work/my-mr-lite/my-mr-lite/middleware/02.buildAuthTokens.global.ts";
import _03_45setup_45gql_45client_45global from "/home/runner/work/my-mr-lite/my-mr-lite/middleware/03.setupGqlClient.global.ts";
import _04_45load_45account_45global from "/home/runner/work/my-mr-lite/my-mr-lite/middleware/04.loadAccount.global.ts";
import manifest_45route_45rule from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45check_45apex_45domain_45global,
  _02_45build_45auth_45tokens_45global,
  _03_45setup_45gql_45client_45global,
  _04_45load_45account_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}