import { useJwtTokenStore } from '~/stores/jwtTokenStore';
import { isExpired } from '~/utils/jwt/jwtEncoder';

export function reloadAppIfExpired(statusCode: number) {
    const tokens = useJwtTokenStore();

    if (
        statusCode === 401
        && (isExpired(tokens.getMrAdminApiToken) || isExpired(tokens.getMrApiToken))
        && !process.server
    ) {
        reloadNuxtApp({
            path: useRoute().fullPath
        });

        return true;
    }

    return false;
}
