
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "sk",
  "de_DE",
  "hu_HU",
  "en"
]

export const localeLoaders = {
  "cs": [],
  "sk": [],
  "de_DE": [],
  "hu_HU": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../nuxt-i18n.ts?hash=369546b3&config=1" /* webpackChunkName: "__nuxt_i18n_ts_369546b3" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt-i18n.ts",
  "locales": [
    {
      "code": "cs",
      "language": "cs-CZ"
    },
    {
      "code": "sk",
      "language": "sk-SK"
    },
    {
      "code": "de_DE",
      "language": "de-DE"
    },
    {
      "code": "hu_HU",
      "language": "hu-HU"
    },
    {
      "code": "en",
      "language": "en-US"
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "language": "cs-CZ",
    "files": []
  },
  {
    "code": "sk",
    "language": "sk-SK",
    "files": []
  },
  {
    "code": "de_DE",
    "language": "de-DE",
    "files": []
  },
  {
    "code": "hu_HU",
    "language": "hu-HU",
    "files": []
  },
  {
    "code": "en",
    "language": "en-US",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
