import * as jose from 'jose';
import * as crypto from 'crypto';
import {
    JWT_AUDIENCE_ADMIN_API, JWT_AUDIENCE_MR_API,
    JWT_GUEST_FIRSTNAME, JWT_GUEST_LASTNAME,
    JWT_GUEST_USER_ID,
    JWT_MUNIPOLIS_APP_MY_MR, JWT_TOKEN_ISSUER,
    JWT_USER_SOURCE_GUEST
} from '~/utils/constants';

export async function createMrApiToken(privateKey: string): Promise<string> {
    return createGuestJwtToken(
        privateKey,
        [
            'city:read',
            'posts:read',
            'questions:read',
            'rss-items:read',
        ],
        JWT_AUDIENCE_MR_API,
    );
}

export async function createMrAdminApiToken(privateKey: string): Promise<string> {
    return createGuestJwtToken(
        privateKey,
        [
            'account:read',
            'custom-menu-item:read',
            'custom-page:read-public',
        ],
        JWT_AUDIENCE_ADMIN_API,
    );
}

function createGuestJwtToken (jwtPrivateKey: string, abilities: Array<string>, audience: string): Promise<string> {
    const alg = 'RS256';
    const privateKey = crypto.createPrivateKey({ key: jwtPrivateKey });

    const jwtPayload = {
        iat: Math.floor(Date.now() / 1000), // time when the token was generated
        iss: JWT_TOKEN_ISSUER, // issuer
        nbf: Math.floor(Date.now() / 1000) - 1, // not before (1 second for Nikolaj time sync issue)
        exp: Math.floor(Date.now() / 1000) + 7200, // expire (2 hours)
        id: JWT_GUEST_USER_ID,
        source: JWT_USER_SOURCE_GUEST,
        munipolis_app: JWT_MUNIPOLIS_APP_MY_MR,
        email: null,
        name: JWT_GUEST_FIRSTNAME,
        surname: JWT_GUEST_LASTNAME,
        abilities: abilities,
        audience: audience,
        allowed_account_ids: ['*'],
    };

    return new jose.SignJWT(jwtPayload)
        .setProtectedHeader({ alg })
        .sign(privateKey);
}

export function isExpired(jwt: string): boolean {
    const exp = jose.decodeJwt(jwt).exp ?? 0;

    return Date.now() >= exp * 1000;
}
