import { defineStore } from 'pinia';
import type { IJwtTokenState } from '~/types/store.types';
import { computed, ref } from 'vue';

export const useJwtTokenStore = defineStore('jwtTokenStore', () => {

    // State
    const state = ref<IJwtTokenState>({
        mrApiToken: '123456789',
        mrAdminApiToken: 'abcdefgh',
    });

    // Getters
    const getMrApiToken = computed((): string => {
        return state.value.mrApiToken;
    });

    const getMrAdminApiToken = computed((): string => {
        return state.value.mrAdminApiToken;
    });

    // Actions
    function setMrApiToken(mrApiToken: string) {
        state.value.mrApiToken = mrApiToken;
    }

    function setMrAdminApiToken(mrAdminApiToken: string) {
        state.value.mrAdminApiToken = mrAdminApiToken;
    }

    return {
        state,
        getMrApiToken,
        getMrAdminApiToken,
        setMrApiToken,
        setMrAdminApiToken,
    };
});
