import { startsWithWww, apexUrl } from '~/utils/ApexDomainCheck';
export default defineNuxtRouteMiddleware(async () => {
    if (startsWithWww(useRequestURL())) {
        return navigateTo(
            apexUrl(useRequestURL()),
            {
                redirectCode: 301,
                external: true,
            }
        );
    }
});
