import revive_payload_client_vGR3SzBZYt from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kvJt5QrNKa from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kkO7lvg1ym from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Xts4YMwt5a from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2DKzF8Kkh1 from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9sxPksPlus from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vQcemFggq1 from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_akBY3wertU from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/@pinia+nuxt@0.4.11_lxmnsfj5te2uun6hfya6faidhm/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/my-mr-lite/my-mr-lite/.nuxt/components.plugin.mjs";
import prefetch_client_8GBBQSSWES from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt@3.12.4_kzoq5ho4vfxvuaggqkunvwyuj4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_e85KcUPAIB from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0+vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_oiq0Stb2dq from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0+vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_kdca6cv9QQ from "/home/runner/work/my-mr-lite/my-mr-lite/node_modules/.pnpm/nuxt-graphql-client@0.2.36_yghxk4dazq5bmjjs4v2o2gwuq4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_3AyO8nEfhE from "/home/runner/work/my-mr-lite/my-mr-lite/plugins/sentry.ts";
import gqlOnError_TEPIhfOMpt from "/home/runner/work/my-mr-lite/my-mr-lite/plugins/gqlOnError.ts";
export default [
  revive_payload_client_vGR3SzBZYt,
  unhead_kvJt5QrNKa,
  router_kkO7lvg1ym,
  payload_client_Xts4YMwt5a,
  navigation_repaint_client_2DKzF8Kkh1,
  check_outdated_build_client_9sxPksPlus,
  chunk_reload_client_vQcemFggq1,
  plugin_vue3_akBY3wertU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8GBBQSSWES,
  switch_locale_path_ssr_e85KcUPAIB,
  i18n_oiq0Stb2dq,
  plugin_kdca6cv9QQ,
  sentry_3AyO8nEfhE,
  gqlOnError_TEPIhfOMpt
]