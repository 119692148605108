import * as Sentry from '@sentry/vue';

async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (!process.client) return;

    const { Replay } = await import('@sentry/vue');
    Sentry.addIntegration(new Replay({
        maskAllText: false,
        blockAllMedia: false,
    }));
}

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();
    const browserTracing = new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    });

    return [browserTracing];
}

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp;

    if (useRuntimeConfig().public.sentryDsn) {
        Sentry.init({
            app: [vueApp],
            dsn: useRuntimeConfig().public.sentryDsn,
            integrations: getSentryIntegrations(),
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1,
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();

        vueApp.mixin(Sentry.createTracingMixins({
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update']
        }));

        Sentry.attachErrorHandler(vueApp, {
            logErrors: false,
            attachProps: true,
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update']
        });

        return {
            provide: {
                sentrySetContext: Sentry.setContext,
                sentrySetUser: Sentry.setUser,
                sentrySetTag: Sentry.setTag,
                sentryAddBreadcrumb: Sentry.addBreadcrumb,
                sentryCaptureException: Sentry.captureException
            }
        };
    }
});
