import { createError } from 'h3';
import type { ICustomPage } from '~/types/adminApi';

export async function getCustomPageData(accountId: number, slug: string): Promise<ICustomPage> {
    const { publicCustomPage } = await GqlPublicCustomPage({ accountId: accountId.toString(), slug: slug });

    if (!publicCustomPage) {
        throw createError({ statusCode: 404, name: 'Admin API error', statusMessage: 'Custom Page Not Found.' });
    }

    return publicCustomPage;
}
