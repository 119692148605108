import { default as CustomPage13Fxo3lhT7Meta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/CustomPage.vue?macro=true";
import { default as DashboardPagevIRdLZfihoMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/DashboardPage.vue?macro=true";
import { default as HomepageFNNlNiT9rRMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/Homepage.vue?macro=true";
import { default as MenuCollectionPageL9cgtyTvY6Meta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/MenuCollectionPage.vue?macro=true";
import { default as PostsPageu0HDMMl1aIMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/PostsPage.vue?macro=true";
import { default as RssPageXTDoMw1BUUMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/RssPage.vue?macro=true";
import { default as SinglePostPagebSugybh2wQMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/SinglePostPage.vue?macro=true";
import { default as SitemapPageYNmImnZ7NIMeta } from "/home/runner/work/my-mr-lite/my-mr-lite/pages/SitemapPage.vue?macro=true";
export default [
  {
    name: "CustomPage",
    path: "/CustomPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/CustomPage.vue").then(m => m.default || m)
  },
  {
    name: "DashboardPage",
    path: "/DashboardPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/DashboardPage.vue").then(m => m.default || m)
  },
  {
    name: "Homepage",
    path: "/Homepage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/Homepage.vue").then(m => m.default || m)
  },
  {
    name: "MenuCollectionPage",
    path: "/MenuCollectionPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/MenuCollectionPage.vue").then(m => m.default || m)
  },
  {
    name: "PostsPage",
    path: "/PostsPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/PostsPage.vue").then(m => m.default || m)
  },
  {
    name: "RssPage",
    path: "/RssPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/RssPage.vue").then(m => m.default || m)
  },
  {
    name: "SinglePostPage",
    path: "/SinglePostPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/SinglePostPage.vue").then(m => m.default || m)
  },
  {
    name: "SitemapPage",
    path: "/SitemapPage",
    component: () => import("/home/runner/work/my-mr-lite/my-mr-lite/pages/SitemapPage.vue").then(m => m.default || m)
  }
]