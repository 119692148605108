import { createError } from 'h3';
import type { AccountDetailResponse, MenuItem } from '~/types/adminApi';

export async function getAccountByDomain(domain: string): Promise<AccountDetailResponse> {
     const { accountDetail } = await GqlAccountDetail({ domain: domain });

    if (!accountDetail) {
        throw createError({ statusCode: 404, name: 'Admin API error', statusMessage: 'Account for domain not found.' });
    }

    if (!accountDetail.appCityId) {
        throw createError({ statusCode: 404, name: 'Admin API error', statusMessage: 'Missing appCityId for account.' });
    }

    return accountDetail;
}

export async function getMenuItems(accountId: number): Promise<MenuItem[]> {
    const { accountCustomMenuItemsTree } = await GqlAccountCustomMenuItemsTree({ accountId: accountId });

    if (!accountCustomMenuItemsTree || !accountCustomMenuItemsTree.length) {
        throw createError({ statusCode: 404, name: 'Admin API error', statusMessage: 'Menu items not found.' });
    }

    return accountCustomMenuItemsTree;
}
