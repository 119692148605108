import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { INavItem } from '~/types/types';
import type { INavigationStore } from '~/types/store.types';
import { EMenuType } from '~/types/enums';

export const useNavigationStore = defineStore('navigationStore', () => {

    // State
    const state = ref<INavigationStore>({
        navItems: [],
        activeNavItems: [],
        currentPageTitle: '',
        currentPageDescription: '',
        hiddenHeader: false,
        currentPageIconSvg: null,
        loadedAt: 0,
        isLoading: true,
        hasArchive: false,
        showArchive: false
    });

    // Getters
    const currentNavigationLevel = computed((): number => {
        return state.value.activeNavItems.length;
    });

    const getFirstNavItem = computed((): INavItem => {
        return state.value.navItems[0];
    });

    const getLastActiveNavItem = computed((): INavItem => {
        return state.value.activeNavItems[state.value.activeNavItems.length -1];
    });

    const getNavItems = computed((): INavItem[] => {
        return state.value.navItems;
    });

    const isLoading = computed((): boolean => {
        return state.value.isLoading;
    });

    const isHiddenHeader = computed((): boolean => {
        return state.value.hiddenHeader;
    });

    const showArchive = computed((): boolean => {
        return state.value.showArchive;
    });

    // Actions
    const getActiveNavItemForGivenLevel = (level: number): INavItem => {
        return state.value.activeNavItems[level - 1];
    };

    const setNavItems = (navItems: INavItem[]) => {
        state.value.navItems = navItems;
    };

    const setLoadedAt = (loadedAt: number) => {
        state.value.loadedAt = loadedAt;
    };

    const setShowArchive = (archive: boolean) => {
        state.value.showArchive = archive;
    };

    const setLoading = (loading: boolean) => {
        state.value.isLoading = loading;
    };

    const setHiddenHeader = (hiddenHeader: boolean) => {
        state.value.hiddenHeader = hiddenHeader;
    };

    const setActiveNavItems = (navItem: INavItem, level: number, goingBackInNavigation = false) => {
        const isMenuCollection = navItem.type === EMenuType.MENU_COLLECTION;
        const isDifferentNavItem = state.value.activeNavItems[level - 1] !== navItem;

        // only show loader if clicking on other than currently active item and not menu collection item
        if (!isMenuCollection && (isDifferentNavItem || goingBackInNavigation)) {
            setLoading(true);
        }

        const newActiveItems = state.value.activeNavItems.slice(0, level - 1);
        newActiveItems.push(navItem);
        state.value.activeNavItems = newActiveItems;
    };

    const setPageDetails = (
        pageTitle: string,
        pageDescription = '',
        hiddenHeader: boolean,
        pageIconSvg?: HTMLElement,
    ) => {
        state.value.currentPageTitle = pageTitle;
        state.value.currentPageDescription = pageDescription;
        state.value.hiddenHeader = hiddenHeader;
        state.value.showArchive = false;
        if (pageIconSvg) {
            state.value.currentPageIconSvg = pageIconSvg;
        }
    };

    const setPageDescription = (pageDescription: string) => {
        state.value.currentPageDescription = pageDescription;
    };

    return {
        state,
        currentNavigationLevel,
        getFirstNavItem,
        getNavItems,
        getLastActiveNavItem,
        isLoading,
        isHiddenHeader,
        showArchive,
        getActiveNavItemForGivenLevel,
        setNavItems,
        setLoadedAt,
        setActiveNavItems,
        setPageDetails,
        setPageDescription,
        setLoading,
        setHiddenHeader,
        setShowArchive,
    };
});
